var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "850" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  height: _vm.tableSize,
                  loading: _vm.loading,
                  items: _vm.items,
                  "items-per-page": -1,
                  dense: "",
                  "fixed-header": "",
                  "hide-default-footer": "",
                  "no-data-text": "No Items to Display",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.item_id`,
                      fn: function ({ item }) {
                        return [
                          item.item_id
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.item_id) + " "),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]
                      },
                    },
                    {
                      key: `item.qty_per_case`,
                      fn: function ({ item }) {
                        return [
                          item.qty_per_case
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.qty_per_case) + " "),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]
                      },
                    },
                    {
                      key: `item.last_90_days_shipped_qty`,
                      fn: function ({ item }) {
                        return [
                          item.last_90_days_shipped_qty != null
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.last_90_days_shipped_qty) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]
                      },
                    },
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "main",
                              attrs: { dark: "", flat: "" },
                            },
                            [
                              _c("v-toolbar-title", [
                                _vm._v(
                                  " " + _vm._s(_vm.selectedItem.item_desc) + " "
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$emit("close")
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }